import _get from 'lodash/get';
import styled from '@emotion/styled';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Navbar, Nav, NavDropdown, Modal, Button, Spinner } from 'react-bootstrap';
import { BellFill, BoxArrowRight, Person } from 'react-bootstrap-icons';

import { theme } from 'components/themes/v1';
import { userLogout } from 'services/account';
import { clearAdminTokenStorage, clearAdminDataStorage, clearLastRequestTime } from 'utils/localStorage';
import { getLatestNotif } from 'services/notification';
import { storeAdminLatestNotif, storeAdminUnreadNotif } from 'store/actions/notification';

import { AdminNotification } from '.';
import UserDummy from 'assets/images/user-dummy.jpg';


const NavbarIcon = styled.span`
  display: inline-block;
  position: relative;
  font-size: 1.25rem;
`;

const NavbarIconBadge = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  color: #fff;
  background-color: ${theme.colors.danger};
  font-size: 11px;
  min-width: 14px;
  height: 14px;
  text-align: center;
  border-radius: 50%;
  top: 2px;
  right: -5px;
  padding: 0 1px;
`;

const UserImage = styled.img`
  width: 30px;
  height: 30px;
  margin: auto 12px auto 0;
  border: 1px solid #ddd;
  border-radius: ${(props) => (props.circle ? '50%' : 0)};
`;

const AdminHeader = (props) => {
  const [modalLogout, setModalLogout] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);

  const adminData = useSelector((state) => state.account.adminDetail);
  const adminName = _get(adminData, 'name') || '';
  const adminProfilePic = _get(adminData, 'profile.profile_picture') || '';

  const notifUnread = useSelector((state) => state.adminNotif.unread);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onLogout = async () => {
    setLogoutLoading(true);
    const resp = await userLogout();
    setLogoutLoading(false);

    if (resp.success) {
      // clear token & data
      clearAdminTokenStorage();
      clearAdminDataStorage();
      clearLastRequestTime();

      toast.success(t('logout_success'));

      setTimeout(() => {
        window.location.href = '/admin';
      }, 500);
    } else {
      toast.error(t('http.request_failed'));
    }
  };

  useEffect(() => {
    (async () => {
      const notif = await getLatestNotif();

      if (notif.success) {
        const notifData = _get(notif, 'data.notifications') || [];
        const notifUnread = _get(notif, 'data.unread') || 0;
        dispatch(storeAdminLatestNotif(notifData));
        dispatch(storeAdminUnreadNotif(notifUnread));
      }
    })();
  }, []);

  return (
    <Navbar fixed='top' bg='light' expand='lg' className='navbar-aimsis-admin' style={{ paddingLeft: '250px' }}>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='mr-auto' />
        <Nav>
          <h5 className='mr-2 mt-2'>{t('menu.welcome')}, <span style={{ color: '#DD1555', fontWeight: 'bold' }}>{adminName}</span></h5>
          <NavDropdown className='mr-2'
            title={
              <NavbarIcon>
                <BellFill />
                <NavbarIconBadge show={notifUnread && notifUnread > 0}>
                  {notifUnread >= 100 ? '99+' : notifUnread}
                </NavbarIconBadge>
              </NavbarIcon>
            }
            id='basic-nav-dropdown'
            alignRight
          >
            <AdminNotification />
          </NavDropdown>

          <NavDropdown className='d-inline-block customwidth'
            title={<UserImage circle src={adminProfilePic ? adminProfilePic : UserDummy} alt='-' />}
            alignRight
          >
            <NavDropdown.Item onClick={() => navigate('/admin/profile')}>
              <NavbarIcon className='mr-2'>
                <Person />
              </NavbarIcon>
              {t('button.profile')}
            </NavDropdown.Item>
            {/* logout */}
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => setModalLogout(true)}>
              <NavbarIcon className='mr-2'>
                <BoxArrowRight />
              </NavbarIcon>
              {t('button.logout')}
            </NavDropdown.Item>
          </NavDropdown>

        </Nav>
      </Navbar.Collapse>

      <Modal show={modalLogout} onHide={() => setModalLogout(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='text-center pb-4'>
          {t('are_you_sure_to_logout')}

          <div className='mt-3'>
            <Button variant='primary' onClick={() => onLogout()} className='mr-3'>
              {logoutLoading ? <Spinner animation='border' variant='light' size='sm' /> : null} {t('button.yes')}
            </Button>
            <Button variant='secondary' onClick={() => setModalLogout(false)}>
              {t('button.no')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
};

export default AdminHeader;

export const langID = {
  // grouped translations
  error: {},
  lang: {
    indonesia: 'Indonesia',
    english: 'English',
  },

  menu: {
    candidate_management: 'MANAJEMEN KANDIDAT',
    jobs: 'LOWONGAN PEKERJAAN',
    settings: 'PENGATURAN',
    users: 'PENGGUNA',
    school_data: 'Data Sekolah',
    master_data: 'MASTER DATA',
    job_status: 'Status Pekerjaan',
    skill: 'Keahlian',
    school_name: 'Nama Sekolah',
    school_location: 'Lokasi Sekolah',
    job_position: 'Posisi Pekerjaan',
    job_kind: 'Jenis Pekerjaan',
    register: 'Daftar',
    login: 'Masuk',
    welcome_name: 'Selamat datang {{name}}!',
    hello_name: 'Hello, {{name}}',
    staff_personalia: 'Staff Personalia',
    admin: 'Admin',
    user: 'User',
    archive: 'ARSIP',
    job_archive: 'Arsip Lowongan',
    candidate_archive: 'Arsip Lamaran',
    notifications: 'Notifikasi',
    welcome: 'Selamat Datang',
  },

  form: {
    foundation_name: 'Nama Yayasan',
    foundation_address: 'Alamat Yayasan',
    phone_number: 'Nomor Telepon',
    website: 'Situs Web',
    vision: 'Visi',
    mission: 'Misi',
    profile: 'Profile',
    logo: 'Logo',
    choose_file: 'Pilih File',
    saved: 'Data tersimpan',
    description: 'Deskripsi',
    name: 'Nama',
    search: 'Cari',
    birth_date: 'Tanggal Lahir',
    password: 'Password',
    email: 'Email',
    title: 'Posisi Pekerjaan',
    school: 'Sekolah',
    placement: 'Penempatan',
    location: 'Lokasi',
    job_position: 'Jenis Pekerjaan',
    status: 'Status',
    skill: 'Keahlian',
    ended_at: 'Berakhir Pada',
    minimum_salary: 'Gaji Minimal',
    maximum_salary: 'Gaji Maksimal',
    job_description: 'Deskripsi Pekerjaan',
    job_status_description: 'Deskripsi Status Pekerjaan',
    skill_description: 'Deskripsi Keahlian',
    qualification: 'Kualifikasi',
    required_skill: 'Keahlian Wajib',
    religion: 'Agama',
    choose_pic: 'Pilih Gambar',
    max_size_pic: 'Maksimal {{size}} MB',
    choose_profile_pic: 'Pilih Foto Profile',
    allowed_pic_file: 'Tipe file yang di perbolehkan',
    fullname: 'Nama Lengkap',
    nickname: 'Nama Panggilan',
    no_hp: 'No. HP',
    nomor_hp: 'Nomor HP',
    alt_no_telp: 'Alternatif No. TLP',
    email_address: 'Alamat Email',
    social_media: 'Sosial Media',
    gender: 'Jenis Kelamin',
    weight: 'Berat Badan',
    height: 'Tinggi Badan',
    blood_type: 'Golongan Darah',
    birth_place_date: 'Tempat/Tanggal Lahir',
    religion: 'Agama',
    ethnic: 'Suku Bangsa',
    married_status: 'Status Perkawinan',
    total_child: 'Jumlah Anak',
    upload_cv: 'Upload CV',
    upload_ktp: 'Upload KTP',
    upload_npwp: 'Upload NPWP',
    upload_ijazah: 'Upload Ijazah & Transkrip Nilai Terakhir',
    upload_paklaring: 'Upload Surat Keterangan Kerja',
    upload_sk_domisili: 'Upload Surat Keterangan Domisili',
    upload_skck: 'Upload SKCK',
    upload_pas_photo: 'Upload Pas Foto 3x4',
    upload_bank_account: 'Upload Nomor Rekening Bank NISP',
    upload_kk: 'Upload Kartu Keluarga',
    upload_medical_checkup: 'Upload Pemeriksaan Medis',
    birth_place: 'Tempat Lahir',
    birth_date: 'Tanggal Lahir',
    stage: 'Tahap',
    reason: 'Alasan',
    choose_stage: 'Pilih Tahap',
    edit_password: 'Ubah Password',
    edit_email: 'Ubah Email',
    new_email: 'Email Baru',
    old_password: 'Password Lama',
    new_password: 'Password Baru',
    password_confirmation: 'Konfirmasi Password',
    academic_title: 'Gelar',
  },

  button: {
    login: 'Masuk',
    register: 'Daftar',
    learn_more: 'Learn More',
    send_verification_link: 'Send Verification Link',
    send: 'Kirim',
    save: 'Simpan',
    update: 'Ubah',
    cancel: 'Batal',
    submit: 'Submit',
    save_logo: 'Simpan Logo',
    add_new: 'Tambah Baru',
    delete: 'Hapus',
    reset: 'Reset',
    reset_password: 'Reset Password',
    search: 'Cari',
    filter: 'Filter',
    archive: 'Arsipkan',
    apply: 'Lamar',
    share: 'Bagikan',
    detail: 'Detail',
    see: 'Lihat',
    login: 'Masuk',
    create_new_account: 'Buat Akun Baru',
    upload: 'Upload',
    stage: 'Stage',
    reject: 'Reject',
    download: 'Download',
    send_task: 'Send Task',
    send_message: 'Kirim Pesan',
    edit: 'Ubah',
    yes: 'Ya',
    no: 'Tidak',
    export: 'Ekspor',
    edit_password: 'Ubah Password',
    edit_email: 'Ubah Email',
    activate: 'Aktifkan',
    previous: 'Kembali',
    next: 'Selanjutnya',
    continue: 'Lanjut',
    hire: 'Hire',
    send_offer: 'Kirim Penawaran',
    logout: 'Keluar',
    profile: 'Profil',
    history: 'Riwayat',
    export_profile: 'Ekspor Profil',
  },

  profile_tab: {
    profile: 'Identitas Pribadi',
    family: 'Keluarga',
    experience: 'Pendidikan & Pekerjaan',
    health: 'Riwayat Kesahatan',
    interest: 'Peminatan',
    other: 'Informasi Lain',
    reference: 'Referensi & Pernyataan',
  },

  stage: {
    received: 'Lamaran Diterima',
    send_task: 'Send Task',
    task_sent: 'Task Sent',
    rejected: 'Ditolak',
    declined: 'Menolak',
    for_interview: 'Tahap Interview',
    induction: 'Induksi',
    offered: 'Penawaran',
    hired: 'Diterima',
    application_sent: 'Permohonan Lamaran Telah Terkirim',
    task_received: 'Task Received',
    interview_scheduled: 'Terjadwal Interview',
    offering: 'Offering',
    for_test: 'Tahap Test',
    administration: 'Administrasi',
    input_offer: 'Input Penawaran',
  },

  application: {
    offered_amount: 'Nominal gaji yang ditawarkan',
    offer_sent: 'Penawaran terkirim',
  },

  role: {
    superadmin: 'Superadmin',
    admin: 'Admin',
    candidate: 'Kandidat',
  },

  sosmed: {
    ig: 'Instagram',
    tw: 'Twitter',
    fb: 'Facebook',
    yt: 'Youtube',
  },

  gender: {
    f: 'Wanita',
    m: 'Pria',
  },

  http: {
    request_failed: 'Request failed. there something wrong',
  },

  toast: {},

  passwords: {
    sent: 'Email terkirim',
    reset: 'Password berhasil direset',
    throttled: 'Mohon tunggu sebelum mengirim email lagi',
    token: 'Token reset password invalid',
    user: 'User tidak ditemukan',
  },

  // general translations
  name: 'Nama',
  last_login: 'Last login',
  birthday: 'Birthday',
  full_name: 'Full name',
  first_name: 'First name',
  last_name: 'Last name',
  username: 'Username',
  email: 'Email',
  phone: 'Phone',
  password: 'Password',
  password_confirmation: 'Konfirmasi Password',
  forgot_password: 'Lupa Password?',
  forgot_password_instruction: 'Masukkan email dan ikuti instruksi untuk reset password',
  login_as: 'Login As',
  login_success: 'Login Success',
  login_failed: 'Login Failed',
  register_success: 'Register Success',
  register_failed: 'Register Failed',
  description: 'Deskripsi',
  add_new: 'Tambah Baru',
  update: 'Ubah',
  delete: 'Hapus',
  saved: 'Tersimpan',
  other_jobs: 'Lowongan Pekerjaan Lain',
  link_copied: 'Link Copied!',
  see_all_jobs: 'Lihat Semua Lowongan Kerja',
  or: 'Atau',
  login_failed: 'Login Failed',
  login_success: 'Login Success',
  update_profile: 'Update Profile',
  location: 'Lokasi',
  contact: 'Kontak',
  website: 'Website',
  vision: 'Visi',
  mission: 'Misi',
  profile: 'PROFILE {{name}}',
  apply_for: 'Melamar Untuk',
  desired_position: 'Posisi yang diinginkan',
  reject: 'Reject',
  send_task: 'Send Task',
  reset_password: 'Reset Password',
  edit: 'Ubah',
  page: 'Halaman',
  from: 'dari',
  are_you_sure_to_logout: 'Apakah anda yakin ingin keluar?',
  logout_success: 'Logout Success',
  reason: 'Reason',
  archived: 'Archived',
  see_all: 'Lihat Semua',
  hire: 'Hire',
  are_you_sure: 'Apakah anda yakin?',
  confirmation: 'Konfirmasi',
  time: 'Jam',
  place: 'Tempat',
  induction_date: 'Tanggal Induksi',
  induction_notes: 'Keterangan Induksi',
  interview_date: 'Tanggal Interview',
  interview_notes: 'Keterangan Interview',
  interview_reschedule_date: 'Tanggal Reschedule Interview',
  interview_reschedule_request: 'Tidak bisa interview pada tanggal',
  waiting_interview_reschedule: 'Menunggu reschedule interview',
  rejection_reason: 'Alasan penolakan',
  offered_status: 'Status Penerimaan',
  accept: 'Ya',
  decline: 'Tidak',
  reason: 'Alasan',
  reason_reject: 'Alasan Penolakan Interview',
  reason_reschedule: 'Alasan Ganti Jadwal Interview',
  cannot_empty: ' tidak boleh kosong',
  times_applied: 'Banyak Lamaran',
  offering: 'Penawaran',
  offer_amount: 'Nominal Penawaran',
  see_detail: 'Lihat Detail',
  count_applications: 'Jumlah Lamaran',
  history_application: 'Riwayat Lamaran',
  download_cv: 'Unduh CV',
};

export default langID;

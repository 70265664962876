import _get from 'lodash/get';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { PersonLinesFill, BriefcaseFill, PeopleFill, GearWideConnected, DatabaseFill, Calculator, SuitcaseLg, ArchiveFill, Archive } from 'react-bootstrap-icons';

const SidebarWrapper = styled.div`
  width: 245px;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 1040;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  & .pro-sidebar .pro-menu .pro-menu-item {
    font-size: 12px;
  }
`;

const SidebarHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 36px 12px;
`;

const SidebarLogo = styled.img`
  width: 70px;
`;

// const SidebarBrand = styled.div`
//   margin: auto 0;
//   font-weight: bold;
// `;

const SidebarMenu = styled.div`
  width: 100%;
`;

var countClick = 0;
var path = '';

const Sidebar = (props) => {
  const schoolData = useSelector((state) => state.school.detail);

  const schoolLogo = _get(schoolData, 'logo') || '';
  const schoolName = _get(schoolData, 'name') || '';

  const { t } = useTranslation();


  function updatePath(firstLoad = false){
    if(firstLoad !== true){
      firstLoad = false;
    }

    var urlRightNow = window.location.href;

    path = urlRightNow.split('/admin/')[1];
    path = path.split('?')[0];

    //remove last slash
    if (path.slice(-1) === '/') {
      path = path.slice(0, -1);
    }

    if(!firstLoad){
      countClick = 0;
    }
    setTimeout(() => {
      clickParentActive();
    }, 500);
  }

  updatePath(true);

  function clickParentActive(){
    // Find .menuParent.active and click
    const activeMenuParent = document.querySelector('.menuParent.active');
    if (activeMenuParent && countClick === 0){
      countClick++;
      activeMenuParent.querySelector('.pro-inner-item').click();
    }
  }

  return (
    <SidebarWrapper className='shadow-3'>
      <SidebarHeader>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <SidebarLogo src={schoolLogo} alt='-' />
          {/* <SidebarBrand>{schoolName}</SidebarBrand> */}
          <h4 className='mt-1'>{schoolName}</h4>
        </div>
      </SidebarHeader>

      <SidebarMenu>
        <ProSidebar>
          <Menu iconShape='square'>
            <MenuItem className={'menuParent ' + (path === 'candidates' ? 'active' : '')}>
              <PersonLinesFill className='mr-2' style={{ marginBottom: '4px' }} />
              <span>{t('menu.candidate_management')}</span>
              <Link to='/admin/candidates' onClick={updatePath} />
            </MenuItem>

            <MenuItem className={'menuParent ' + (path === 'jobs' ? 'active' : '')}>
              <BriefcaseFill className='mr-2' style={{ marginBottom: '4px' }} />
              <span>{t('menu.jobs')}</span>
              <Link to='/admin/jobs' onClick={updatePath} />
            </MenuItem>

            <MenuItem className={'menuParent ' + (path === 'users/candidate' ? 'active' : '')}>
              <PeopleFill className='mr-2' style={{ marginBottom: '4px' }} />
              <span>{t('menu.users')}</span>
              <Link to='/admin/users/candidate' onClick={updatePath} />
            </MenuItem>

            {/* <SubMenu title={t('menu.users')}>
              <MenuItem>
                {t('menu.admin')}
                <Link to='/admin/users/admin' onClick={updatePath} />
              </MenuItem>
              <MenuItem>
                {t('menu.user')}
                <Link to='/admin/users/candidate' onClick={updatePath} />
              </MenuItem>
            </SubMenu> */}

            <MenuItem className={'menuParent ' + (path === 'setting/school' ? 'active' : '')}>
              <GearWideConnected className='mr-2' style={{ marginBottom: '4px' }} />
              <span>{t('menu.settings')}</span>
              <Link to='/admin/setting/school' onClick={updatePath} />
            </MenuItem>

            <SubMenu className={'menuParent ' + (path.includes('master') ? 'active' : '')}
            title={
              <div>
                <DatabaseFill className='mr-2' style={{ marginBottom: '4px' }} />
                <span>{t('menu.master_data')}</span>
              </div>
            }>
              <MenuItem className={'menuSubParent ' + (path === 'master/jobposition' ? 'active' : '')}>
                <Calculator className='mr-2' style={{ marginBottom: '4px' }} />
                <span>{t('menu.job_kind')}</span>
                <Link to='/admin/master/jobposition' onClick={updatePath} />
              </MenuItem>
              <MenuItem className={'menuSubParent ' + (path === 'master/jobstatus' ? 'active' : '')}>
                <SuitcaseLg className='mr-2' style={{ marginBottom: '4px' }} />
                <span>{t('menu.job_status')}</span>
                <Link to='/admin/master/jobstatus' onClick={updatePath} />
              </MenuItem>
              {/* <MenuItem>
                {t('menu.skill')}
                <Link to='/admin/master/skill' onClick={updatePath} />
              </MenuItem>
              <MenuItem>
                {t('menu.school_name')}
                <Link to='/admin/master/school' onClick={updatePath} />
              </MenuItem>
              <MenuItem>
                {t('menu.school_location')}
                <Link to='/admin/master/location' onClick={updatePath} />
              </MenuItem> */}
            </SubMenu>

            <SubMenu className={'menuParent ' + (path.includes('archive') ? 'active' : '')}
            title={
              <div>
                <ArchiveFill className='mr-2' style={{ marginBottom: '4px' }} />
                <span>{t('menu.archive')}</span>
              </div>
            }>
              <MenuItem className={'menuSubParent ' + (path === 'archive/jobs' ? 'active' : '')}>
                <Archive className='mr-2' style={{ marginBottom: '4px' }} />
                <span>{t('menu.job_archive')}</span>
                <Link to='/admin/archive/jobs' onClick={updatePath} />
              </MenuItem>
              <MenuItem className={'menuSubParent ' + (path === 'archive/candidates' ? 'active' : '')}>
                <Archive className='mr-2' style={{ marginBottom: '4px' }} />
                <span>{t('menu.candidate_archive')}</span>
                <Link to='/admin/archive/candidates' onClick={updatePath} />
              </MenuItem>
            </SubMenu>
          </Menu>
        </ProSidebar>
      </SidebarMenu>

    </SidebarWrapper>
  );
};

export default Sidebar;

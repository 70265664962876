import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';

import { ErrorFallback } from 'components/handler';

import App from './App';
import store from 'store';

/**
 * Jika belum ada DSN key dari sentry,
 * lebih baik config .env REACT_APP_SENTRY_DSN dikosongkan
 */
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const handleError = (error, info) => {
  // error handling
  // error logging
};

ReactDOM.render(
  // <React.StrictMode>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        <App />
      </ErrorBoundary>
    </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
